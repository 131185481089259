import { MutableRefObject } from 'react';
import type { TExperiment } from '@printdeal/feature-management/react';
import { scrollIntoView } from '@printdeal/ui-library';
import type { WindowLocation } from '@gatsbyjs/reach-router';
import type { SegmentationData } from '@printdeal/segmentation/react';
import type { MarketingBannerExperimentData, HomePageBannerData } from '../../components/homepage/banner/types';
import {
  getFromLocalStorage, LS_KEYS, windowPresent, parseStringifiedValue, setInLocalStorage,
} from '../../helpers/window';

export interface AudiencesData {
  type: string | null | undefined;
}

export interface AudienceOverRide {
  isNull: boolean;
  queryKey: string | null;
}

export const SUPPORTED_AUDIENCE_TYPES = [
  'web_retail_industry',
  'web_catering_industry',
  'web_card_buyers',
  'web_frequent_buyers',
  'web_test_audience',
  'web_shop_decoration_buyers',
  'web_gift_buyers',
  'web_resellers_dwd_pd',
];

export const scrollToRef = (target: MutableRefObject<HTMLDivElement | null>) => {
  if (!target?.current) return;

  scrollIntoView(target.current, {
    block: 'start',
    behavior: 'smooth',
  });
};

/**
 *
 * @param param0 experiment object from statsig
 * @param marketingBanner homepage banner record from contentful or experiment
 * @param isInitialized flag from statsig
 * @returns if no experiment returns the homepage banner record, if yes returns the variant
 */
export const getBannerDataForVariations = (
  { variation }: TExperiment,
  marketingBanner: HomePageBannerData & MarketingBannerExperimentData,
  isInitialized: Boolean,
) => {
  if (!marketingBanner?.control) return marketingBanner;
  if (variation === 'variant' && isInitialized) return marketingBanner?.variant;
  return marketingBanner?.control;
};

/**
 *
 * @param audienceType audience type per loggedin user's industry
 * @param allMarketingBanners all banner records in contentful of content-type homepage-banner
 * @returns only matching homepage-banner record for audience
 */
export const getBannerDataForAudience = (
  audienceType: string | null | undefined,
  allMarketingBanners: HomePageBannerData[],
  /**
   * Since we get from Contentful a string with comma separated audiences,
   * we have to split it and get the first value.
   * We are planning to operate multiple audiences in the future.
   */
) => allMarketingBanners.find((bannerData) => bannerData.audienceType?.split(',')[0] === audienceType);

/**
 *
 * @param accountId customer id from customer slice/ redux store
 * @returns returns userId either from LS or Customer Slice, which one is available
 */
export const isRecognizedUser = (accountId: string): boolean => {
  let ajsUserId: string | null = parseStringifiedValue(getFromLocalStorage(LS_KEYS.AJS_USER_ID));
  ajsUserId = ajsUserId === 'null' ? null : ajsUserId;
  const userId = ajsUserId || accountId;
  return !!userId;
};

/**
 *
 * @param location location from gatsby reach router
 * @param queryKey the exact query parameter, we are looking for
 * @returns an object with queryKey and it's value and whether the query key is valid
 */
export const getQueryParamFromLocation = (location: WindowLocation, queryKey: string) => {
  if (windowPresent) {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(queryKey);
  }
  return null;
};

/**
 * Moved this method from useMarketingBanner() hook to helper,
 * which decouples audience data from banner hook
 * @param customerTraits traits data which contains audiences
 * @param audienceLoadedFromSegment isSuccess from useSegmentation() hook
 * @param audienceOverRide over-ride params from url for playground
 * @returns current audience type
 */

export const getAudiences = (
  customerTraits: SegmentationData | undefined,
  audienceLoadedFromSegment: boolean,
  audienceOverRideForPlayground: string | null | undefined,
) => {
  const isAudienceOverRidden = Boolean(audienceOverRideForPlayground);
  /**
   * if audience override is present in the url location, ignore all other cases
   * & set the audience type from url location query parameters
   */
  if (isAudienceOverRidden) {
    return {
      type: audienceOverRideForPlayground,
    };
  }

  if (!isAudienceOverRidden && audienceLoadedFromSegment) {
    const filteredAudiences = customerTraits?.audiences
      .filter((audience) => SUPPORTED_AUDIENCE_TYPES.includes(audience))?.[0] || 'no_audience';
    /**
     * If the user is recognized, we need to store the audience type as well in LS
     */
    setInLocalStorage(LS_KEYS.AUDIENCE_TYPE, filteredAudiences);
    return {
      type: filteredAudiences,
    };
  }
  // Traits with no audience, display default content.
  return {
    type: 'no_audience',
  };
};
