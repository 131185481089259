import { createContext } from 'react';
import type { Actions, ActionsMap } from './AnalyticsTrackerProvider';

export interface TrackingIds {
  recommendationId?: string | undefined;
  analyticsId?: string | undefined;
  placementId?: string | undefined;
  userId?: string | undefined;
  anonymousId?: string | undefined;
}

export interface TrackingData {
  trackingIds?: TrackingIds;
  productIdsForEventsLogged?: {
    productsClicked?: string[];
    productsAddedToCart?: string[];
    orderCreatedForProducts?: string[];
  }
}

export interface AnalyticsTracker {
  ctRecommendations: TrackingData[];
  algoliaRecommendations: TrackingData[]
}

export type Dispatcher = <
  Type extends Actions['type'],
  Payload extends ActionsMap[Type],
>(
  type: Type,
  ...payload: Payload extends undefined ? [undefined?] : [Payload]
) => void;

export type AnalyticsTrackerContextInterface = [AnalyticsTracker, Dispatcher];

const AnalyticsTrackerContext = createContext<AnalyticsTrackerContextInterface>([
  { algoliaRecommendations: [], ctRecommendations: [] },
  () => { },
]);

export default AnalyticsTrackerContext;
