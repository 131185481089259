import _configurationFile from '../../static/configuration/configuration.json';
import { Configuration, RecommendationsConfig } from '../types/Configuration';

const configurationFile = _configurationFile as unknown as Configuration;

const getConfiguration = () => configurationFile;

const getUrl = (configuration: Configuration) => (
  configuration.channel ? configuration.channel.url : null
);

const getBaseUrl = (key: keyof Configuration['baseUrls'], configuration = configurationFile) => {
  if (configuration.baseUrls && configuration.baseUrls[key]) {
    return configuration.baseUrls[key];
  }

  throw new Error(`Base URL "${key}" is missing in configuration file.`);
};

/**
 * Which countries are valid for usage within checkout?
 *
 * @param {Configuration} configuration
 */
const getAddressCountries = (configuration: Configuration = configurationFile): Array<string> => (
  configuration.addressCountries && configuration.addressCountries.length > 0
    ? configuration.addressCountries : ['NL']);

/**
* Returns Recommendation Configuration
*
* @param {Configuration} configuration
* @return {object}
*/
const getRecommendationsConfig = (configuration: Configuration = configurationFile): RecommendationsConfig | {} => (
  configuration.recommendationsConfig ? configuration.recommendationsConfig : {}
);

/**
* Returns split-shipping countries
*
* @param {Configuration} configuration
* @return {array}
*/
const getSplitShippingCountries = (configuration: Configuration = configurationFile): Array<string> => (
  configuration.splitShippingCountries ? configuration.splitShippingCountries : ['NL', 'BE']);

/**
* Returns default postal code for PUP
*
* @param {Configuration} configuration
* @return {string}
*/
const getPUPDefaultPostalCode = (
  configuration: Configuration = configurationFile,
): string => configuration.pickupPoint.defaults.postalCode;

/**
* Returns default country for PUP
*
* @param {Configuration} configuration
* @return {string}
*/
const getPUPDefaultCountry = (
  configuration: Configuration = configurationFile,
): string => configuration.pickupPoint.defaults.countryCode;

/**
* Returns a js object, containing boolean flags for feature toggle as per brand requirements
* e.g. FSC logo in the footer is not to be shown for impressto brand etc
* @param {Configuration} configuration
* @return {Object}
*/
const getBrandFromConfig = (
  configuration: Configuration = configurationFile,
) => ({
  IS_IMPRESSTO_FR: configuration?.channel?.brandName === 'brandImpresstoFR',
  IS_DWD_NL: configuration?.channel?.brandName === 'brandDrukwerkdealNL',
  IS_PRINTDEAL_BE: configuration?.channel?.brandName === 'brandPrintdealBE',
});

export {
  getConfiguration,
  getUrl,
  getBaseUrl,
  getAddressCountries,
  getRecommendationsConfig,
  getSplitShippingCountries,
  getPUPDefaultPostalCode,
  getPUPDefaultCountry,
  getBrandFromConfig,
};
