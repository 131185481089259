import type {
  Breadcrumb,
  Primitive,
  Extras,
  SeverityLevel,
} from '@sentry/types';
import { customAlphabet } from 'nanoid';
import { ErrorManagement } from '@printdeal/error-management/react';
import { getFromLocalStorage, LS_KEYS, setInLocalStorage } from './window';

let debugId: string | undefined;

export interface Exception {
  e: any,
  level?: SeverityLevel,
  extra?: Extras,
  tags?: Record<string, Primitive>,
  fingerPrint?: string[],
}

class SentryHelper {
  /**
   * Add a custom breadcrumb to Sentry for better traceability of errors
   * @param category string? - a subtype of breadcrumb, such as "ui.click" or "ui.input"
   * @param message string? - a text message to display in the breadcrumb
   * @param level string? - the level of the breadcrumb. Allowed values are "fatal", "critical", "error", "warning",
   *    "log", "info", and "debug"
   * @param data object? - additional data to display in the breadcrumb
   */
  static addBreadcrumb({
    category, message, level, data,
  }: Breadcrumb) {
    return ErrorManagement.addTrace({
      category: category as string,
      message: message as string,
      level: level as SeverityLevel,
      data: data as Record<string, Primitive>,
    });
  }

  static exception({
    e,
    extra = {},
    tags = {},
    fingerPrint = ['{{ default }}'],
  }: Exception) {
    return ErrorManagement.exception({
      exception: e,
      extra: {
        ...extra as Record<string, Primitive>,
      },
      tags,
      fingerprint: fingerPrint,
    });
  }

  /**
   * Debug messages can be safely left in production code.
   * They are controlled by GATSBY_SENTRY_DEBUG_MODE env variable inside {@see onClientEntry}.
   * Some events may get deduped, which means Sentry will prevent to send them more than once.
   */
  static debug({
    message,
    extra = {},
    tags = {},
    fingerPrint = ['{{ default }}'],
  }: {
    message: string,
    extra?: Extras,
    tags?: Record<string, Primitive>,
    fingerPrint?: string[],
  }) {
    return ErrorManagement.exception({
      exception: message,
      extra: {
        ...extra as Record<string, Primitive>,
      },
      tags,
      fingerprint: fingerPrint,
    });
  }

  static warning({
    message,
    extra = {},
    tags = {},
    fingerPrint = ['{{ default }}'],
  }: {
    message: string,
    extra?: Extras,
    tags?: Record<string, Primitive>,
    fingerPrint?: string[],
  }) {
    return ErrorManagement.exception({
      exception: message,
      extra: {
        ...extra as Record<string, Primitive>,
      },
      tags,
      fingerprint: fingerPrint,
    });
  }

  /**
   * Be aware of maximum payload size, which is 200kB on individual event payloads.
   * When this happens, you’ll get HTTP Error 413 Payload Too Large.
   */
  static log({
    message,
    extra = {},
    tags = {},
    fingerPrint = ['{{ default }}'],
  }: {
    message: string,
    level: SeverityLevel,
    extra?: Extras,
    tags?: Record<string, Primitive>,
    fingerPrint?: string[],
  }) {
    return ErrorManagement.exception({
      exception: message,
      extra: {
        ...extra as Record<string, Primitive>,
      },
      tags,
      fingerprint: fingerPrint,
    });
  }

  // Needed because of configurationProvider
  static enhanceScope = () => null;

  static getDebugId = (): string => {
    // Return stored value if available
    if (debugId) {
      return debugId;
    }

    // Return localStorage value if available
    debugId = getFromLocalStorage(LS_KEYS.DEBUG_ID);
    if (debugId && debugId.toLowerCase() === debugId) {
      return debugId;
    }

    // Generate and return a new value
    const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz';
    const nanoid = customAlphabet(alphabet, 10);
    debugId = nanoid();
    setInLocalStorage(LS_KEYS.DEBUG_ID, debugId);

    return debugId;
  };
}

export {
  SentryHelper,
};
