import { ErrorManagement } from '@printdeal/error-management/react';
import Api from '../Api';

class TweakWiseService extends Api {
  getSearchResults = async (searchQuery: string, pageNumber: number, itemsPerPage: number) => {
    try {
      const { data } = await this.httpClient.get('/', {
        params: {
          tn_cid: '1-0', // tweakwise root category for search
          tn_q: searchQuery, // Search query or Search term
          tn_p: pageNumber, // page number requested
          tn_ps: itemsPerPage, // Number of products per page
        },
      });

      return data;
    } catch (error) {
      /**
       * Sentry error management
       */
      ErrorManagement.exception({
        exception: error,
        tags: {
          component: 'TweakWiseService',
          fileLocation: 'services-TweakWiseService-getSearchResults',
          featureFunction: 'hook-response-results',
        },
        fingerprint: ['services-TweakWiseService-getSearchResults-response'],
      });

      /**
       * Error for the browser dev console
       */
      throw new Error(`Something went wrong while getting search results from tweakWise: ${error}`);
    }
  };
}

export default TweakWiseService;
