import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { graphql, useStaticQuery } from 'gatsby';
import { Message } from '../types/Message';

const messages: { [key: string ]: Message } = defineMessages({
  slogan: {
    id: 'company.slogan',
    defaultMessage: 'Show who you are',
  },
  description: {
    id: 'company.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Ordering printed matter online has never been easier. Show who you are with your own flyers, letterheads, business cards, banners and other printed communications. You can order fast and cheap printing at',
  },
  productSupported: {
    id: 'company.contactPoint.productSupported',
    defaultMessage: 'Online Printing Company',
  },
});

interface SocialChannel {
  uri: string;
}

interface QueryData {
  site: {
    siteMetadata: {
      configuration: {
        channel: {
          name: string;
          siteUrl: string;
        };
        organisationName: string;
        logo: {
          companyLogo: string;
        };
        officeAddress: {
          streetName: string;
          streetNumber: number;
          city: string;
          postalCode: string;
          countryCode: string;
          phoneNumber: string;
        };
        contactPoint: {
          email: string;
          areaServed: string[];
          availableLanguage: string[];
          geoCoordinates: {
            latitude: number;
            longitude: number;
          };
        };
        openingHours: {
          dayOfWeek: string[];
          opens: string;
          closes: string;
        };
        priceRange: string;
        paymentAccepted: string[];
        social: {
          twitter: SocialChannel;
          facebook: SocialChannel;
          instagram: SocialChannel;
          linkedin: SocialChannel;
          pinterest: SocialChannel;
        }
      }
    }
  }
  kiyohReview: {
    averageRating: number;
    numberReviews: number;
  }
  homepage: {
    nodes: {
      node_locale: string;
      path: string;
      seoDescription: {
        seoDescription: string;
      };
    }[];
  }
}

const OrganizationStructuredData = () => {
  const { site, kiyohReview, homepage } = useStaticQuery<QueryData>(
    graphql`
        {
          site {
            siteMetadata {
              configuration {
                channel {
                  siteUrl
                }
                organisationName
                logo {
                  companyLogo
                }
                officeAddress {
                  streetName
                  streetNumber
                  city
                  postalCode
                  countryCode
                  phoneNumber
                }
                social {
                  twitter {
                    uri
                  }
                  facebook {
                    uri
                  }
                  pinterest {
                    uri
                  }
                  linkedin {
                    uri
                  }
                  instagram {
                    uri
                  }
                }
                contactPoint {
                  email
                  areaServed
                  availableLanguage
                  geoCoordinates {
                    latitude
                    longitude
                  }
                }
                openingHours {
                  dayOfWeek
                  opens
                  closes
                }
                priceRange
                paymentAccepted
              }
            }
          }
          kiyohReview: kiyoh {
            averageRating
            numberReviews
          }
          homepage: allContentfulPageSystem(filter: { pageSytemType: { eq: "home" } }) {
            nodes {
              node_locale
              path
              seoDescription {
                seoDescription
              }
            }
          }
        }
    `,
  );
  const portalConfiguration = site.siteMetadata.configuration;
  interface Node {
    node_locale: string;
    seoDescription: {
      seoDescription: string;
    }
  }
  const intl = useIntl();
  const homepageForLocale = homepage.nodes.find((node: Node) => node.node_locale === intl.locale);
  const homepageMetaDescription = homepageForLocale?.seoDescription.seoDescription || '';
  const {
    channel, organisationName, logo, officeAddress, contactPoint, priceRange, paymentAccepted, openingHours, social,
  } = portalConfiguration;

  const url = `${channel.siteUrl}${homepageForLocale?.path}`;

  const slogan = intl.formatMessage({
    id: messages.slogan.id,
    defaultMessage: messages.slogan.defaultMessage,
  });
  const description = intl.formatMessage({
    id: messages.description.id,
    defaultMessage: messages.description.defaultMessage,
  });
  const productSupported = intl.formatMessage({
    id: messages.productSupported.id,
    defaultMessage: messages.productSupported.defaultMessage,
  });

  const organizationData = {
    '@context': 'https://schema.org/',
    '@type': 'Organization',
    '@id': channel.siteUrl,
    name: organisationName,
    image: logo.companyLogo,
    logo: logo.companyLogo,
    url,
    slogan,
    description,
    makesOffer: homepageMetaDescription,
    brand: {
      '@type': 'Brand',
      name: organisationName,
    },
    sameAs: [
      social.facebook.uri,
      social.instagram.uri,
      social.twitter.uri,
      social.linkedin.uri,
      social.pinterest.uri,
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: `${officeAddress.streetName} ${officeAddress.streetNumber}`,
      addressLocality: officeAddress.city,
      postalCode: officeAddress.postalCode,
      addressCountry: officeAddress.countryCode,
    },
    telephone: officeAddress.phoneNumber,
    email: contactPoint.email,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: officeAddress.phoneNumber,
      email: contactPoint.email,
      contactType: 'customer service',
      areaServed: contactPoint.areaServed,
      availableLanguage: contactPoint.availableLanguage,
      productSupported,
    },
    openingHoursSpecification: {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: openingHours.dayOfWeek,
      opens: openingHours.opens,
      closes: openingHours.closes,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: contactPoint.geoCoordinates.latitude,
      longitude: contactPoint.geoCoordinates.longitude,
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      bestRating: 10,
      worstRating: 1,
      ratingValue: kiyohReview.averageRating,
      reviewCount: kiyohReview.numberReviews,
    },
    priceRange,
    paymentAccepted,
  };

  // https://schema.org/Organization
  return (
    <script
      type="application/ld+json"
      id="organization-structured-data"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationData) }}
    />
  );
};

export default OrganizationStructuredData;
