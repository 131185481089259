import { PriceAttribute, PriceGroup } from '@printdeal/product-selector';
import Api from '../Api';
import { Quote, QuoteStatus } from '../../types/quotes/quotes';
import { CreateQuoteRequest } from '../../types/quotes/quoteRequest';

export const QUOTES_DEFAULTS = {
  LIMIT: 25,
  OFFSET: 0,
};

interface GetQuotesResult {
  results: Quote[];
  total: number;
  limit: number;
  offset:number;
}
interface GetQuoteStatusResult {
  results: QuoteStatus[];
  total: number;
  limit: number;
  offset:number;
}

class QuotesService extends Api {
  async createQuote(payload: CreateQuoteRequest) {
    const { data } = await this.httpClient
      .post('/quotes', payload)
      .catch(this.errorHandler);
    return Promise.resolve(data);
  }

  async getQuote(quoteId: string) {
    const { data } = await this.httpClient
      .get(`/quotes/${quoteId}`)
      .catch(this.errorHandler);
    return Promise.resolve(data);
  }

  async validateQuote(quoteIds: string) {
    const { data } = await this.httpClient
      .get(`/quotes/validate?quoteIds=${quoteIds}`)
      .catch(this.errorHandler);
    return Promise.resolve(data);
  }

  async getQuoteStatuses(): Promise<GetQuoteStatusResult> {
    const { data } = await this.httpClient
      .get('/statuses')
      .catch(this.errorHandler);
    return data;
  }

  async getQuotes(
    limit: number = QUOTES_DEFAULTS.LIMIT,
    offset: number = QUOTES_DEFAULTS.OFFSET,
  ): Promise<GetQuotesResult> {
    const url = '/quotes';
    const queryParams = `?limit=${limit}&offset=${offset}`;

    const { data } = await this.httpClient.get(`${url}${queryParams}`);

    return data;
  }

  async downloadPdf(uuid: string) {
    const { data } = await this.httpClient
      .get(`/quotes/${uuid}/pdf`)
      .catch(this.errorHandler);
    return Promise.resolve(data);
  }

  async sendQuoteRequest(payload: {
    sku: string,
    variableAttributes: PriceAttribute[],
    groups: PriceGroup[],
    quantity: number,
    remarks: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
  }) {
    const { data } = await this.httpClient.post('/quotes/quotes-inventory-check', payload).catch(this.errorHandler);
    return Promise.resolve(data);
  }
}

export default QuotesService;
