import React from 'react';
import { Container } from '@printdeal/ui-library';
import { ErrorManagement } from '@printdeal/error-management/react';
import ErrorBoundaryBody from './ErrorBoundaryBody';
import Logo from '../navigation/header-new/Logo';

interface Props {
  children: React.ReactNode;
  inline?: boolean;
}

interface State {
  error?: Error;
}

const ErrorLayout = () => (
  <Container className="pd-mx-auto">
    <div className="pd-relative pd-grid pd-gap-4 pd-bg-white pd-pt-5">
      <Logo />
      <ErrorBoundaryBody />
    </div>
  </Container>
);

/**
 * https://docs.sentry.io/platforms/javascript/react/
 *
 * "...One important thing to note about the behavior of error boundaries in development mode is that
 * React will rethrow errors they catch." This will result in errors being reported twice to Sentry with
 * the above setup, but this won’t occur in your production build..."
 */
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
    ErrorManagement.exception({
      exception: error,
      extra: {
        ...errorInfo,
      },
    });
  }

  render() {
    const {
      children,
      inline = false,
    } = this.props;

    const { error } = this.state;

    if (!error) {
      return children;
    }

    if (inline) {
      return <ErrorBoundaryBody />;
    }

    return <ErrorLayout />;
  }
}

export default ErrorBoundary;
