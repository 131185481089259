/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { AnonymousCoamIdentity } from '../../helpers/auth';
import { getFromLocalStorage, LS_KEYS } from '../../helpers/window';

const authToken = getFromLocalStorage(LS_KEYS.AUTH_TOKEN);
const anonCOAMIdentity = getFromLocalStorage(LS_KEYS.ANON_COAM_IDENTITY);

interface AuthState {
  isLoading: boolean;
  error: string;
  isLoggedIn: boolean;
  token: string;
  anonymousCOAM: {
    identity?: AnonymousCoamIdentity,
    isVerifying: boolean,
    error: string,
  },
  login: {
    isLoading: boolean,
    error: string
    fullError: any
  },
  logout: {
    isLoading: boolean,
    error: string
  }
}

const initialState: AuthState = {
  isLoading: false,
  error: '',
  isLoggedIn: false,
  token: authToken || '',
  anonymousCOAM: {
    identity: anonCOAMIdentity ? JSON.parse(anonCOAMIdentity) as AnonymousCoamIdentity : undefined,
    isVerifying: false,
    error: '',
  },
  login: {
    isLoading: false,
    error: '',
    fullError: null,
  },
  logout: {
    isLoading: false,
    error: '',
  },
};

/**
 * Authentication slice
 */
export const { reducer, actions } = createSlice({
  name: 'auth',
  initialState: initialState as AuthState,
  reducers: {
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    authenticate: (state, action: PayloadAction<string | undefined>) => {},
    authRequest: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    authSuccess: (state, { payload }) => {
      const { isLoggedIn, token } = payload;

      state.isLoading = false;
      state.error = '';
      state.isLoggedIn = isLoggedIn;
      state.token = token;
    },
    authFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    validateAnonymousCOAMIdentity: (state) => {
      state.anonymousCOAM.isVerifying = true;
      state.anonymousCOAM.error = '';
    },
    validateAnonymousCOAMIdentitySuccess: (state, action: PayloadAction<AnonymousCoamIdentity>) => {
      state.anonymousCOAM = {
        identity: action.payload,
        isVerifying: false,
        error: '',
      };
    },
    validateAnonymousCOAMIdentityFailure: (state, { payload }) => {
      state.anonymousCOAM = {
        identity: undefined,
        isVerifying: false,
        error: payload.error,
      };
    },
    login: () => {},
    loginRequest: (state) => {
      state.login = {
        isLoading: true,
        error: '',
        fullError: null,
      };
    },
    loginSuccess: (state, { payload }) => {
      state.isLoggedIn = true;
      state.token = payload.token;
      state.login = {
        isLoading: false,
        error: '',
        fullError: null,
      };
    },
    loginFailure: (state, { payload }) => {
      state.login = {
        isLoading: false,
        error: payload.error,
        fullError: payload.fullError,
      };
    },
    logout: () => {},
    logoutRequest: (state) => {
      state.logout = {
        isLoading: true,
        error: '',
      };
    },
    logoutSuccess: (state, { payload }) => {
      state.isLoggedIn = false;
      state.token = payload.token; // Anonymous token
      state.logout = {
        isLoading: false,
        error: '',
      };
    },
    logoutFailure: (state, { payload }) => {
      state.logout = {
        isLoading: false,
        error: payload.error,
      };
    },
  },
});

/**
 * Selectors
 */
export const selectors = {
  getAuthToken: ({ auth }: RootState) => auth.token || '',
  getAnonymousCOAMToken: ({ auth }: RootState) => auth.anonymousCOAM?.identity?.anonymousToken,
  getIsAnonymousCOAMVerifying: ({ auth }: RootState) => auth.anonymousCOAM.isVerifying,
  isLoggedIn: ({ auth }: RootState) => auth.isLoggedIn,
  isAuthLoading: ({ auth }: RootState) => auth.isLoading || auth.login.isLoading,
  getLoginFullError: ({ auth }: RootState) => auth.login.fullError,
};
