import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'react-intl';

interface ContentfulSystemPage {
  systemPages: {
    nodes: {
      path: string;
      title: string;
      node_locale: string;
      pageSytemType: string;
    }[]
  }
}

interface SystemPage<T> {
  page: T;
  title: string;
  path: string;
}

/**
 * Hook for fetching the pageSystemType title and path
 * @param pageSystemTypes - arguments list of pageSytemTypes
 * @returns
 */
const useSystemPages = <T extends readonly string[]>(...pageSystemTypes: T): { [K in keyof T]: SystemPage<T[K]> } => {
  const { locale } = useIntl();
  const { systemPages } = useStaticQuery<ContentfulSystemPage>(graphql`
    query {
      systemPages: allContentfulPageSystem {
        nodes {
          path
          title
          node_locale
          pageSytemType
        }
      }
    }
  `);

  const pages = pageSystemTypes.map((pageSystemType) => {
    const systemPage = systemPages.nodes.find(
      (node) => node.node_locale === locale && node.pageSytemType === pageSystemType,
    );

    if (!systemPage) {
      // We need this console during Gatsby SSR, otherwise it'll just say the page can't be SSR'd
      // and you'd be clueless as to why
      // eslint-disable-next-line no-console
      console.error(`Cannot find system page '${pageSystemType}'`);
      throw new Error(`Cannot find system page '${pageSystemType}'`);
    }

    return {
      page: systemPage.pageSytemType,
      path: systemPage.path,
      title: systemPage.title,
    };
  });

  return pages as any;
};

export default useSystemPages;
