import Api from '../Api';
import { InvoiceStats } from '../../types/invoices/Invoices';

class FinpackService extends Api {
  getInvoiceStats = async () => {
    const { data } = await this.httpClient.get<InvoiceStats>(
      '/api/v1/invoices/open',
      { blockAnonymous: true },
    );
    return Promise.resolve(data);
  };
}

export default FinpackService;
