import { QueryClient } from '@tanstack/react-query';
import { Design, DesignInfo, DesignNamePayload } from '../../types/design';
import Api from '../Api';

interface SavedDesignsResponse {
  designs: Design[];
  nextPage?: number;
}

interface DuplicateDocRefResponse {
  documentReference: string
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
      placeholderData: (previousData: unknown) => previousData,
    },
  },
});

class DesignService extends Api {
  queryClient = queryClient;

  /**
   * Performs call to obtain customer data.
   */
  async getDesignInfo(designId: string): Promise<DesignInfo> {
    const { data: designInfo } = await this.httpClient.get<DesignInfo>(
      `/get?id=${designId}`,
      {
        headers: {
          'Accept-Version': 'v2',
        },
      },
    );
    return Promise.resolve(designInfo);
  }

  /**
   * Performs call to obtain saved designs for a customer.
   */
  async getSavedDesigns(limit: number, page: number): Promise<SavedDesignsResponse> {
    const { data } = await this.httpClient.get<SavedDesignsResponse>(
      `/saved-designs?limit=${limit}${page ? `&page=${encodeURIComponent(page)}` : ''}`,
    );
    return Promise.resolve(data);
  }

  /**
   * Post request to set the DesignName
   */
  async editDesign(design: DesignNamePayload): Promise<void> {
    await this.httpClient.post(
      `/edit?id=${design.id}`,
      design,
    );
  }

  /**
   * Sets the design to deleted true;
   */
  async deleteDesign(id: string): Promise<string> {
    const { data } = await this.httpClient.delete<string>(`/delete?id=${id}`);
    return Promise.resolve(data);
  }

  /**
   * Copies the current design
   */
  async duplicateDesign(id: string): Promise<string> {
    const { data } = await this.httpClient.get<string>(`/duplicate?id=${id}`);
    return Promise.resolve(data);
  }

  /**
   * Copies a given document reference
   * @returns {string}
   * @param documentReferenceUrl
   */
  async duplicateDocRef(documentReferenceUrl: string): Promise<string> {
    const { data } = await this.httpClient.post<DuplicateDocRefResponse>(
      `/doc-ref/duplicate?documentReference=${documentReferenceUrl}`,
    );
    return data.documentReference;
  }
}

export default DesignService;
