import Api from '../Api';
import { ContentfulProductCollectionData } from '../../hooks/query/useProductImagesContentful';
import { Locale } from '../../types/gatsby';

export const buildContenfulBaseURL = (baseURL: string, spaceId: string, environment: string) =>
  `${baseURL}/spaces/${spaceId}/environments/${environment}`;

class HomePageService extends Api {
  static query = {
    queryGalleryImagesForMultipleRecords: `
      query GetProductContent($productPageIds: [String]!, $totalImages: Int!) {
        productContentCollection(where: {
          sys: {
            id_in: $productPageIds
          }
        }) {
          items{
            productName,
            sys{
              id
            },
            gallery{
              imagesCollection(limit: $totalImages){
                items{
                  url,
                  width,
                  height
                }
              }
            }
          }
        }
      }
    `,
    productReviewPage: `
      query getReviewPage($productPageId: String!, $locale: String!) {
        productContent(id: $productPageId, locale: $locale) {
          slug
          productCategory {
            slug
          }
        }
      }`,
  };

  // eslint-disable-next-line max-len
  getImagesForProductPageIds = async (productPageIds: Array<string>, totalImages: number): Promise<ContentfulProductCollectionData> => {
    try {
      const { data } = await this.httpClient.post<ContentfulProductCollectionData>('/', {
        query: HomePageService.query.queryGalleryImagesForMultipleRecords,
        variables: { productPageIds, totalImages },
      });

      return data;
    } catch (error) {
      throw new Error(`Something went wrong while getting product images from Contentful: ${error}`);
    }
  };

  getReviewPage = async (productPageId: string, locale: Locale) => {
    try {
      const { data } = await this.httpClient.post('/', {
        query: HomePageService.query.productReviewPage,
        variables: { productPageId, locale },
      });

      return data;
    } catch (error) {
      throw new Error(`Something went wrong while getting review page from Contentful: ${error}`);
    }
  };
}

export { HomePageService };
