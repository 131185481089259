import { IAlternativeUpload } from '@printdeal/alternative-upload';
import Api from '../Api';

interface DuplicateDocRefResponse {
  documentReference: string
}

class FileDelivery extends Api {
  getUpdateDocumentSourceUrl = (lineItemId: string, orderId: string, editorType?: string) => {
    if (editorType) {
      return `/updateMcpDocumentsSource?orderId=${orderId}&lineItemId=${lineItemId}&editorType=${editorType}`;
    }
    return `/updateMcpDocumentsSource?orderId=${orderId}&lineItemId=${lineItemId}`;
  };

  removeDocumentSource = async (orderId: string, lineItemId: string) => {
    const {
      data: { message },
    } = await this.httpClient.post(
      this.getUpdateDocumentSourceUrl(lineItemId, orderId),
      { documentReference: '' },
      { withCredentials: true, headers: { 'x-store': process.env.GATSBY_STORE } },
    );
    return Promise.resolve(message);
  };

  async addFileUrlsToLineItem(
    orderId: string,
    lineItemId: string,
    uploadedFiles: IAlternativeUpload[],
    editorType: string,
  ) {
    const { data: response } = await this.httpClient.post(
      `/addLineItemUploadedFile?orderId=${orderId}&lineItemId=${lineItemId}&editorType=${editorType}`,
      { uploadedFiles },
      { withCredentials: true, headers: { 'x-store': process.env.GATSBY_STORE } },
    );

    return Promise.resolve(response);
  }

  deliverMcpDocumentsSource = async (
    orderId: string,
    lineItemId: string,
    documentReferenceUrl: string,
    editorType: string,
  ) => {
    const {
      data: { message },
    } = await this.httpClient.post(
      this.getUpdateDocumentSourceUrl(lineItemId, orderId, editorType),
      { documentReference: documentReferenceUrl },
      { withCredentials: true, headers: { 'x-store': process.env.GATSBY_STORE } },
    ).catch(this.errorHandler);
    return Promise.resolve(message);
  };

  /**
   * Copies a given document reference
   * @returns {string}
   * @param documentReferenceUrl
   */
  async duplicateDocRef(documentReferenceUrl: string): Promise<string> {
    const { data } = await this.httpClient.post<DuplicateDocRefResponse>(
      `/doc-ref/duplicate?documentReference=${documentReferenceUrl}`,
    );
    return data.documentReference;
  }
}

export default FileDelivery;
