import {
  getFromLocalStorage,
  setInLocalStorage,
} from '../../helpers/window';
import { getValueByPath } from '../utils';

/**
 * Middleware for syncing action payload values to localStorage
 */
const maybeSyncPayloadToLocalStorage = ({
  payloadToLocalStorageKeyMap,
  payload,
}) => (
  payloadToLocalStorageKeyMap
    // Maybe sync for every payload/localStorage keypair
    .map(([payloadKey, localStorageKey]) => {
      const oldValue = getFromLocalStorage(localStorageKey);
      const newValue = getValueByPath(payloadKey, payload) || null;
      // Return empty log if no syncing is required
      if (!newValue || String(newValue) === oldValue) {
        return null;
      }
      // Sync newValue to localStorage and return log
      setInLocalStorage(localStorageKey, newValue);
      return {
        localStorageKey,
        oldValue,
        newValue,
      };
    })
    // Remove empty logs
    .filter((log) => Boolean(log))
);

const logToConsole = (logs) => {
  if (!logs.length) return logs;
  /* eslint-disable */
  console.group('localStorage synced');
  logs.forEach((log) => console.log(log.localStorageKey, log));
  console.groupEnd();
  /* eslint-enable */
  return logs;
};

const createLocalStorageMiddleware = ({
  payloadToLocalStorageKeyMap = [],
  isLogger = false,
} = {}) => () => (next) => (action) => {
  const { payload } = action;
  // Continue with next action if action has no payload
  if (!payload) return next(action);

  // Maybe sync for every payload/localStorage keypair
  const logs = maybeSyncPayloadToLocalStorage({
    payloadToLocalStorageKeyMap,
    payload,
  });

  // Log to console if logger is enabled
  if (isLogger) logToConsole(logs);

  // Continue with next action
  return next(action);
};

export default createLocalStorageMiddleware;
