import { MatchedOutcome } from '../types/product/ProductRules';
import { ISelection } from '../types/selector/Selector';

const findMatchedOutcomesOfType = (matchedOutcomes: MatchedOutcome[] | null, typeToFind: string) =>
  matchedOutcomes?.find((outcome) => outcome.type.toLowerCase() === typeToFind.toLowerCase());

const getValueFromMatchedOutcome = (matchedOutcome?: MatchedOutcome, defaultValue: string = '') => (
  matchedOutcome?.params?.value ? matchedOutcome.params.value : defaultValue
);

export const formatAttributes = (attributes: ISelection) =>
  Object.entries(attributes).map(([key, value]) => ({ key, value }));

export const getKeyValueObjectOfMatchedOutcomes = (
  matchedOutcomes: MatchedOutcome[] | null,
  typesToFind: string[] = [],
) => typesToFind.map((type) => findMatchedOutcomesOfType(matchedOutcomes, type))
  .filter((outcome) => outcome)
  .reduce((object, outcome) => {
    const value = getValueFromMatchedOutcome(outcome);
    return outcome
      ? { ...object, [outcome?.type.toLowerCase()]: typeof value === 'boolean' ? value : value.toLowerCase() }
      : object;
  }, {});
