import { AxiosResponse } from 'axios';
import Api from '../Api';
import {
  ISelection,
  ValidationResult,
} from '../../types/selector/Selector';

class ValidationService extends Api {
  validate = async (
    productKey: string,
    selections: ISelection,
    invalidSelections: ISelection,
    customHeaders?: Record<string, string>,
  ): Promise<AxiosResponse<ValidationResult>> => this.httpClient.post<ValidationResult>(
    `${process.env.GATSBY_PRODUCT_VALIDATION_URL}${productKey}:validate`,
    {
      selections,
      invalidSelections,
      includeInformationalAttribute: true,
      applyImpliedInState: true,
    },
    {
      headers: customHeaders || {},
    },
  );
}

export default ValidationService;
