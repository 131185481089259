import { useQuery } from '@tanstack/react-query';
import { ServiceFactory } from '../../services/ServiceFactory';

import { ChatAvailability } from '../../components/widgets/chat/chatTypes';

/**
 *  Hook for fetching the chat availability
 */
const useChatAvailability = (isChatEnabled = true): ChatAvailability => {
  const helpCenterService = ServiceFactory.createHelpCenterService();

  const { data } = useQuery({
    queryKey: ['chat'],
    queryFn: () => helpCenterService.getChatAvailability(),
    retry: 1, // Retry the query once in case of an error
    gcTime: 0, // Set cacheTime to 0 to disable caching for errors
    enabled: isChatEnabled,
  });

  return {
    online: Boolean(data?.online),
    averageWaitingTime: parseInt(data?.averageWaitingTime || '0', 10),
  };
};

export default useChatAvailability;
