import '../../provide-configuration-values';
import '../../provide-implementations';

import { authenticationService } from '@printdeal/authentication';

/**
 * Temporary mechanism to notify the new authentication layer of any token changes in the old authentication layer.
 *
 * There is another similar mechanism, the other way around,
 * in /src/components/authentication/AuthCompatibilityComponent.tsx.
 */
const authCompatibilityMiddleware = () => (next) => (action) => {
  if (
    action.type === 'auth/authSuccess'
    || action.type === 'auth/loginSuccess'
    || action.type === 'auth/logoutSuccess'
  ) {
    authenticationService.syncWithTokenChangeInExternalAuthenticationLayer(action.payload.token);
  }

  if (action.type === 'auth/loginSuccess') {
    authenticationService.syncWithLoginEventInExternalAuthenticationLayer(action.payload);
  } else if (action.type === 'auth/logoutSuccess') {
    authenticationService.syncWithLogoutEventInExternalAuthenticationLayer();
  }

  return next(action);
};

export default authCompatibilityMiddleware;
