import axios, { AxiosHeaders, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { getCOAMToken, getAuthToken } from '../helpers/auth';

const authorizationHeaderRequestInterceptor = async (config: InternalAxiosRequestConfig) => {
  const allowAnonymous = !config.blockAnonymous;
  const updatedConfig = config;

  if (!updatedConfig.headers) {
    updatedConfig.headers = new AxiosHeaders({});
  }

  const token = await getAuthToken(allowAnonymous);
  if (token) {
    updatedConfig.headers.Authorization = token;
  }

  return updatedConfig;
};

const COAMHeaderRequestInterceptor = async (config: InternalAxiosRequestConfig) => {
  const updatedConfig = config;

  if (!updatedConfig.headers) {
    updatedConfig.headers = new AxiosHeaders({});
  }

  const COAMToken = await getCOAMToken();
  if (COAMToken?.anonymousToken) {
    updatedConfig.headers.Authorization = `Bearer ${COAMToken.anonymousToken}`;
  }

  return updatedConfig;
};

const contenfulAPIRequestsInterceptor = async (config: InternalAxiosRequestConfig) => {
  const updatedConfig = config;

  if (!updatedConfig.headers) {
    updatedConfig.headers = new AxiosHeaders({});
  }

  updatedConfig.headers.Authorization = `Bearer ${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`;

  return updatedConfig;
};

const tweakWiseAPIInterceptor = async (config: InternalAxiosRequestConfig) => {
  const updatedConfig = config;

  if (!updatedConfig.headers) {
    updatedConfig.headers = new AxiosHeaders({});
  }

  /**
   * By default tweakwise returns xml, explicitly requesting json
   */
  updatedConfig.headers.Accept = 'application/json';

  return updatedConfig;
};

class HttpClientFactory {
  /**
   * @param {string} baseUrl
   * @param automaticAuth
   * @returns {AxiosInstance}
   */
  static createHttpClient(baseUrl: string, automaticAuth = true): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: baseUrl,
    });

    if (automaticAuth) {
      // By adding the authorization header using an interceptor (instead of
      // during axios.create(), we make sure that even when the axios instance
      // is cached, the inserted token is always the latest value from localStorage.
      axiosInstance.interceptors.request.use(authorizationHeaderRequestInterceptor);
    }

    return axiosInstance;
  }

  static createCOAMClient(baseUrl: string): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    // By adding the authorization header using an interceptor (instead of
    // during axios.create(), we make sure that even when the axios instance
    // is cached, the inserted token is always the latest value from localStorage.
    axiosInstance.interceptors.request.use(COAMHeaderRequestInterceptor);

    return axiosInstance;
  }

  static createContenfulClient(baseUrl: string): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    // By adding the authorization header using an interceptor (instead of
    // during axios.create(), we make sure that even when the axios instance
    // is cached, the inserted token is always the latest value from localStorage.
    axiosInstance.interceptors.request.use(contenfulAPIRequestsInterceptor);

    return axiosInstance;
  }

  static createTweakWiseAPIClient(baseUrl: string): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    // By adding the authorization header using an interceptor (instead of
    // during axios.create(), we make sure that even when the axios instance
    // is cached, the inserted token is always the latest value from localStorage.
    axiosInstance.interceptors.request.use(tweakWiseAPIInterceptor);

    return axiosInstance;
  }
}

export { HttpClientFactory };
