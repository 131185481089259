import Api from '../Api';

class PortalMessage extends Api {
  getPortalMessages = async (locale: string) => {
    const { data } = await this.httpClient.get<Array<{ message: string }>>('/launchpad', {
      params: {
        locale,
        store: process.env.GATSBY_STORE,
      },
    });
    return Promise.resolve(data);
  };
}

export { PortalMessage };
