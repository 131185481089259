import { createContext } from 'react';
import { ChatSettings } from './chatTypes';

const defaultValue: ChatSettings = {
  chatStatus: 'initial',
  setChatStatus: () => {},
  locale: '',
  locationHref: '',
  locationPathName: '',
  chatConfig: {
    enabled: false,
    hasKnowledgeBase: false,
    zendeskKey: '',
    channel: '',
  },
  identity: {
    email: '',
    name: '',
  },
  chatAvailability: {
    online: false,
    averageWaitingTime: 0,
  },
};

const ChatContext = createContext(defaultValue);

export default ChatContext;
