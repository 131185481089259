export const getAlgoliaApiId = (): string => {
  if (typeof process.env.GATSBY_ALGOLIA_APP_ID !== 'string' || process.env.GATSBY_ALGOLIA_APP_ID === '') {
    throw (new Error('The env variable GATSBY_ALGOLIA_APP_ID could not be found.'));
  }
  return process.env.GATSBY_ALGOLIA_APP_ID;
};

export const getAlgoliaReadApiKey = (): string => {
  if (typeof process.env.GATSBY_ALGOLIA_READ_API_KEY !== 'string' || process.env.GATSBY_ALGOLIA_READ_API_KEY === '') {
    throw (new Error('The env variable GATSBY_ALGOLIA_READ_API_KEY could not be found.'));
  }
  return process.env.GATSBY_ALGOLIA_READ_API_KEY;
};

export const getStore = (): string => {
  if (typeof process.env.GATSBY_STORE !== 'string' || process.env.GATSBY_STORE === '') {
    throw (new Error('The env variable GATSBY_STORE could not be found.'));
  }
  return process.env.GATSBY_STORE;
};

export const Environment = process.env.GATSBY_ACTIVE_ENV === 'production' ? 'prod' : 'staging';

export const storeEquals = (site: string): boolean => getStore() === site;
export const isSiteNl = () => storeEquals('drukwerkdeal-nl');
export const isSiteBe = () => storeEquals('printdeal-be');
