import { addEventEntry, addDataLayerEntry } from '@printdeal/data-layer-manager';

const dispatchDataLayerEventOnOpenChat = (component: 'cs_page' | 'chat_button') => {
  // GA3
  addEventEntry({
    eventCategory: 'Zopim Livechat',
    eventAction: 'Button Clicked',
    eventLabel: component,
  });

  // GA4
  addDataLayerEntry('Zopim Livechat', {
    location: window.location.href,
    component,
    action: 'Button Clicked',
  });
};

export {
  dispatchDataLayerEventOnOpenChat,
};
