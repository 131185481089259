const getTags = (channel: string) => [channel];

export const canUseDOM = () => Boolean(typeof window !== 'undefined'
  && window.document
  && window.document.createElement);

const isChatAvailable = () => Boolean(canUseDOM() && window.zE);

export const insertSettings = (zendeskKey: string, channel: string, hasKnowledgeBase: boolean) => {
  if (zendeskKey && canUseDOM() && !window.zESettings) {
    window.zESettings = {
      zendeskKey,
      defer: true,
      analytics: false,
      webWidget: {
        position: {
          horizontal: 'left',
          vertical: 'bottom',
        },
        chat: {
          connectOnPageLoad: true,
          tags: getTags(channel),
          departments: {
            select: channel,
          },
        },
        helpCenter: {
          suppress: hasKnowledgeBase,
        },
        contactForm: {
          suppress: true,
          tags: getTags(channel),
        },
        launcher: {
          mobile: {
            labelVisible: false,
          },
        },
      },
    };
  }
};

export const handleUpdatePath = (
  locationHref: string,
) => {
  if (isChatAvailable()) {
    window.zE('webWidget', 'updatePath', {
      url: locationHref,
      title: document.title,
    });
  }
};

export const handleSuppressionHelpCenter = (
  suppress: boolean,
) => {
  if (isChatAvailable() && window.zESettings.webWidget.helpCenter.suppress !== suppress) {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        helpCenter: {
          suppress,
        },
      },
    });
  }
};

export const handleHelpCenterSuggestions = (
  locationPathName: string,
) => {
  if (isChatAvailable()) {
    // The new auto-suggestions for help need to be retrieved as the user browses the site.
    if (locationPathName === '/') {
      window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'empty' });
    } else {
      window.zE('webWidget', 'helpCenter:setSuggestions', { url: true });
    }
  }
};

export const handleUpdateLocale = (locale: string) => {
  if (isChatAvailable()) {
    window.zE('webWidget', 'setLocale', locale);
  }
};

export const handleUpdateUser = (name: string, email: string) => {
  if (isChatAvailable() && name && email) {
    window.zE('webWidget', 'identify', {
      name,
      email,
    });
    window.zE('webWidget', 'prefill', {
      name: { value: name },
      email: { value: email },
    });
  }
};

// Function to handle opening and closing of Zendesk chat
export const handleStatus = (status: 'open' | 'close') => {
  if (isChatAvailable()) {
    // Call Zendesk API to open or close the chatWidget
    window.zE('webWidget', status);
  }
};
