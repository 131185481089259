/**
 * Clear body scroll locks
 */
export const clearBodyScrollLocks = () => {
  const bodyScrollLocks = [{
    target: document.body,
    className: 'modal-open',
  }, {
    target: document.body,
    className: 'mopinion-modal-active',
  }];

  // Clear each body scroll locking className
  bodyScrollLocks.forEach(
    ({ target, className }) => target.classList.remove(className),
  );
  // Clear inline body scroll lock
  document.body.style.overflow = '';
};
