import { useAuthentication } from '@printdeal/authentication';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../store';
import { actions as authActions } from '../../store/slices/authSlice';

/**
 * Temporary mechanism to notify the old authentication layer of any token changes in the new authentication layer.
 *
 * There is another similar mechanism, the other way around,
 * in /src/store/middleware/authCompatibilityMiddleware.
 */
export default () => {
  const authentication = useAuthentication();

  const authToken = useSelector<RootState, string | undefined>(({ auth }) => auth.token);

  useEffect(() => {
    const unsubscribe = authentication.syncExternalAuthenticationLayerWithTokenChange((token) => {
      if (authToken !== token) {
        store.dispatch(authActions.authenticate(token));
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return null;
};
