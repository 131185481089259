/**
 * @description Check whether a pathname starts with /{localeCode}/account
 */
const isMyAccountPage = (pathname: string) => /^\/[a-z]{2}\/account.*/.test(pathname);

/**
 * @description Gatsby Head cannot set the <title> for PrivatePages, so we use <Helmet> to set the
 * title for those. Because of that, we occasionally end up with with 2 <title> tags, because
 * Helmet can't seem to clean up after itself.
 */
export const dedupeTitle = (location: string, prevLocation: string) => {
  // Coming from MyAccount to Gatsby Head-powered pages
  if (isMyAccountPage(prevLocation) && !isMyAccountPage(location)) {
    const helmetTitle = document.querySelector('title:not([data-gatsby-head])');
    if (helmetTitle !== null) {
      helmetTitle.remove();
    }

    return;
  }

  // Coming from Gatsby Head-powered pages to MyAccount
  if (!isMyAccountPage(prevLocation) && isMyAccountPage(location)) {
    const gatsbyHeadTitle = document.querySelector('title[data-gatsby-head]');
    if (gatsbyHeadTitle !== null) {
      gatsbyHeadTitle.remove();
    }
  }
};
