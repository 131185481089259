import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ZendeskChatProps, ZendeskChatOutput } from './chatTypes';
import {
  canUseDOM,
  insertSettings,
  handleUpdatePath,
  handleUpdateUser,
  handleStatus,
  handleUpdateLocale,
  handleSuppressionHelpCenter,
  handleHelpCenterSuggestions,
} from './chat-helper';
import ChatContext from './ChatContext';

// The main useZendeskChat hook
const useZendeskChat = ({
  onLoading = () => {},
  onOpen = () => {},
  onClose = () => {},
}: ZendeskChatProps): ZendeskChatOutput => {
  // Reference to the script element
  const {
    chatStatus,
    setChatStatus,
    chatConfig: {
      zendeskKey,
      channel,
      hasKnowledgeBase = false,
    },
    locationHref,
    locationPathName,
    locale,
    identity: {
      name,
      email,
    },
  } = useContext(ChatContext);
  const scriptRef = useRef<HTMLScriptElement | null>(null);
  const [areSuggestionsEnabled, setAreSuggestionsEnabled] = useState<boolean>(chatStatus === 'open' && hasKnowledgeBase);

  // Callback when the Zendesk script is loaded
  const onScriptLoaded = () => {
    onLoading();
    handleUpdatePath(locationHref);
    handleUpdateLocale(locale);
    handleUpdateUser(name, email);

    window.zE('webWidget:on', 'open', () => {
      window.zE('webWidget', 'show');
      setChatStatus('open');
    });

    window.zE('webWidget:on', 'close', () => {
      window.zE('webWidget', 'hide');
      setChatStatus('waiting');
    });

    window.zE('webWidget:on', 'chat:connected', () => {
      handleStatus('open');
    });
  };

  // Insert the Zendesk script
  const insertScript = () => {
    if (canUseDOM() && !window.zE) {
      scriptRef.current = document.createElement('script');
      scriptRef.current.defer = true;
      scriptRef.current.id = 'ze-snippet';
      scriptRef.current.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
      scriptRef.current.addEventListener('load', onScriptLoaded);
      document.body.appendChild(scriptRef.current);
    }
  };

  // Update the path for zendesk chat
  useEffect(() => {
    handleUpdatePath(locationHref);
  }, [locationHref]);

  // Update whether auto-suggestions should be enabled or not
  useEffect(() => {
    setAreSuggestionsEnabled(chatStatus === 'open' && hasKnowledgeBase);
  }, [chatStatus, hasKnowledgeBase]);

  // If auto-suggestions are enabled, serve the necessary info.
  // If not, suppress the feature completely.
  useEffect(() => {
    if (areSuggestionsEnabled) {
      handleSuppressionHelpCenter(false);
      handleHelpCenterSuggestions(locationPathName);
    } else {
      handleSuppressionHelpCenter(true);
    }
  }, [areSuggestionsEnabled, locationPathName]);

  // Update the user for zendesk chat
  useEffect(() => {
    handleUpdateUser(name, email);
  }, [name, email]);

  // Exposed methods for opening and closing Zendesk chat
  const openChat = () => {
    setChatStatus('loading');
    insertSettings(zendeskKey, channel, hasKnowledgeBase);
    insertScript();
    handleStatus('open');
    onOpen();
  };
  const closeChat = () => {
    handleStatus('close');
    onClose();
  };

  // Return the exposed methods
  return { openChat, closeChat };
};

export default useZendeskChat;
