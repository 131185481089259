import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@gatsbyjs/reach-router';
import { useFeatureToggle } from '@printdeal/feature-management/react';

// @ts-expect-error
import { selectors as customerSelectors } from '../../../store/slices/customerSlice';
import { RootState } from '../../../store';

import ChatProvider from './ChatProvider';
import { getLocaleCodeFromLocale } from '../../../helpers/translations';

interface Props {
  children: React.ReactNode;
}

const Chat = ({ children }: Props) => {
  const intl = useIntl();
  const locale = getLocaleCodeFromLocale(intl.locale);
  const location = useLocation();
  const firstName = useSelector<RootState, string>(customerSelectors.getCustomerFirstName);
  const email: string = useSelector<RootState, string>(customerSelectors.getCustomerEmail);
  const { featureToggle: { isEnabled: isChatEnabled } } = useFeatureToggle('chat_widget');

  const { portalConfiguration } = useStaticQuery(graphql`
    {
      portalConfiguration {
        chat {
          enabled
          channel
          zendeskKey
        }
      }
    }
  `);
  const { chat: chatConfig } = portalConfiguration;

  return (
    <ChatProvider
      locale={locale}
      identity={{ name: firstName, email }}
      locationHref={location.href}
      locationPathName={location.pathname}
      chatConfig={{
        ...chatConfig,
        enabled: Boolean(chatConfig.enabled && chatConfig.zendeskKey && chatConfig.channel && isChatEnabled),
        hasKnowledgeBase: false,
      }}
    >
      {children}
    </ChatProvider>
  );
};

export default Chat;
