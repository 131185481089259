import { createSlice } from '@reduxjs/toolkit';
import { actions as authActions } from './authSlice';
import {
  createInitialEntityState,
  createEntityReducers,
  createMapEntitiesPayloadToState,
  createInitialAsyncActionState,
  createAsyncActionReducers,
  capitalize,
} from '../utils';
import { GeneralSettings } from '../../helpers/GeneralSettings';
import { getAddressCountries, getConfiguration, getSplitShippingCountries } from '../../helpers/Configuration';

const validAddressCountries = getAddressCountries(getConfiguration());
const validSplitShippingCountries = getSplitShippingCountries(getConfiguration);

const EnrichmentStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: '',
  mandates: [],
  updateCustomer: {
    isLoading: false,
    error: '',
  },
  enrichRegistration: {
    status: EnrichmentStatus.IDLE,
    error: '',
  },
  getMandates: {
    isLoading: false,
    error: '',
  },
  getAccount: createInitialAsyncActionState(),
  removeAddressById: {},
  customer: {
    companyName: '',
    vatId: '',
    custom: {
      fields: {
        cocNumber: '',
        isCompany: '',
      },
    },
  },
  account: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    newsletterOptIn: '',
  },
  surveyDetails: [],
  surveySuccess: false,

  /**
   * @deprecated
   *
   * TODO: remove once all code that depends on this is migrated to the new address book.
   */
  addresses: createInitialEntityState({
    perPage: GeneralSettings.pagination.addressBook.perPage,
  }),
};

const mapCustomerDataToState = (data) => {
  const {
    addresses,
    ...customer
  } = data;

  return customer;
};

const mapAccountPayloadToState = ({ state, payload }) => ({
  id: payload.id || state.account.id,
  firstName: payload.firstName || state.account.firstName,
  lastName: payload.lastName || state.account.lastName,
  email: payload.email || state.account.email,
  newsletterOptIn: payload.newsletterOptIn || state.account.newsletterOptIn,
});

/**
 * @deprecated
 *
 * TODO: remove once all code that depends on this is migrated to the new address book.
 */
const mapAddressesToState = createMapEntitiesPayloadToState({
  type: 'addresses',
  merge: false,
  normalizer: ({ entity: address }) => ({
    ...address,
    id: address.id,
    firstName: address.firstName || '',
    lastName: address.lastName || '',
    streetName: address.streetName || '',
    streetNumber: address.streetNumber || '',
    postalCode: address.postalCode || '',
    city: address.city || '',
    country: address.country || '',
    email: address.email || '',
    phone: address.phone || '',
    company: address.company || '',
    vatId: address.vatId || '',
    isDefaultBillingAddress: address.isDefaultBillingAddress || false,
    isDefaultShippingAddress: address.isDefaultShippingAddress || false,
    isAddressValid: address.isAddressValid || false,
    isCountryValid: validAddressCountries.includes(address.country),
    isSplitShippingCountryValid: validSplitShippingCountries.includes(address.country),
  }),
});

const handleGetAccountSuccess = (state, { payload }) => {
  // This method is run multiple time as people log in/browse the site.
  // Depending on how and where this method is triggered, it may not have the customer and surveyDetail data.
  const { account, customer } = payload;
  state.account = mapAccountPayloadToState({
    state,
    payload: account,
  });

  if (account.surveyDetails) {
    state.surveyDetails = account.surveyDetails;
  }

  if (customer) {
    state.customer.companyName = customer.companyName;
    state.customer.vatId = customer.vatId;
    state.customer.custom.fields.cocNumber = customer.cocNumber;
    state.customer.custom.fields.isCompany = customer.isCompany;
  }
};

/**
 * Customer slice
 */
export const { reducer, actions } = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    ...createAsyncActionReducers({
      type: 'getAccount',
      onSuccess: handleGetAccountSuccess,
    }),
    ...createAsyncActionReducers({
      type: 'updateAccount',
      onSuccess: handleGetAccountSuccess,
    }),
    getCustomer: () => { },
    getCustomerRequest: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    getCustomerSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = '';
      state.customer = mapCustomerDataToState(payload.customer);
      state.addresses = mapAddressesToState({
        state,
        payload: payload.customer.addresses,
      });
    },
    getCustomerFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    updateCustomerRequest: (state) => {
      state.updateCustomer = {
        isLoading: true,
        error: '',
      };
    },
    updateCustomerSuccess: (state, { payload }) => {
      state.updateCustomer = {
        isLoading: false,
        error: '',
      };
      state.customer = mapCustomerDataToState(payload.customer);
      state.addresses = mapAddressesToState({
        state,
        payload: payload.customer.addresses,
      });
    },
    updateCustomerFailure: (state, { payload }) => {
      state.updateCustomer = {
        isLoading: false,
        error: payload.error,
      };
    },
    enrichRegistration: () => { },
    enrichRegistrationRequest: (state) => {
      state.enrichRegistration = {
        status: EnrichmentStatus.LOADING,
        error: '',
      };
    },
    enrichRegistrationSuccess: (state, { payload }) => {
      state.enrichRegistration = {
        status: EnrichmentStatus.LOADED,
        error: '',
      };
      state.customer = mapCustomerDataToState(payload.customer);
      state.account = mapAccountPayloadToState({ state, payload: payload.account });

      state.addresses = mapAddressesToState({
        state,
        payload: payload.customer.addresses,
      });
    },
    enrichRegistrationFailure: (state, { payload }) => {
      state.enrichRegistration = {
        status: EnrichmentStatus.ERROR,
        error: payload.error,
      };
    },
    getMandates: () => { },
    getMandatesRequest: (state) => {
      state.getMandates = {
        isLoading: true,
        error: '',
      };
    },
    getMandatesSuccess: (state, { payload }) => {
      state.getMandates = {
        isLoading: false,
        error: '',
      };
      state.mandates = payload.mandates;
    },
    getMandatesFailure: (state, { payload }) => {
      state.getMandates = {
        isLoading: false,
        error: payload.error,
      };
    },
    updateSurveyDetails: () => { },
    updateSurveyDetailsSuccess: (state, { payload }) => {
      state.surveyDetails = payload.surveyDetails;
    },

    /**
     * @deprecated
     *
     * TODO: remove once all code that depends on this is migrated to the new address book.
     */
    ...createEntityReducers({
      type: 'addresses',
    }),

    setCustomer: (state, { payload }) => {
      state.customer = mapCustomerDataToState(payload.customer);
    },
    resetCustomer: () => initialState,
  },
  extraReducers: {
    [authActions.logoutRequest]: () => initialState,
    [authActions.loginSuccess]: handleGetAccountSuccess,
  },
});

/**
 * Selectors
 */

/**
 * @deprecated
 *
 * TODO: remove once all code that depends on this is migrated to the new address book.
 */
const getValidDefaultAddress = ({
  type = GeneralSettings.AddressTypes.BILLING,
  state = initialState,
}) => {
  const addressKey = `default${capitalize(type)}AddressId`;
  const addressId = state.customer[addressKey];
  const address = state.addresses.byId[addressId];
  return address?.isAddressValid ? address : null;
};

export const selectors = {
  getCustomerId: ({ customer }) => customer?.data?.id || null,
  getCustomerGroupId: ({ customer }) => customer?.customer?.customerGroup?.id || null,
  getCustomerData: ({ customer }) => customer.customer,
  getCustomerFirstName: ({ customer }) => customer.account.firstName || '',
  getCustomerLastName: ({ customer }) => customer.account.lastName || '',
  getCustomerEmail: ({ customer }) => customer.customer.email || '',
  getCustomerNumber: ({ customer }) => customer.customer.customerNumber || '',
  getCustomerType: ({ customer }) => customer.customer.customerType || '',

  /**
   * @deprecated
   *
   * TODO: remove once all code that depends on this is migrated to the new address book.
   */
  getCustomerAddresses: ({ customer }) => customer.addresses || {},

  /**
   * @deprecated
   *
   * TODO: remove once all code that depends on this is migrated to the new address book.
   */
  getDefaultBillingAddress: ({ customer }) => getValidDefaultAddress({
    type: GeneralSettings.AddressTypes.BILLING,
    state: customer,
  }),

  /**
   * @deprecated
   *
   * TODO: remove once all code that depends on this is migrated to the new address book.
   */
  getDefaultShippingAddress: ({ customer }) => getValidDefaultAddress({
    type: GeneralSettings.AddressTypes.SHIPPING,
    state: customer,
  }),

  getAccountId: ({ customer }) => customer.account.id || '',
  getAccountEmail: ({ customer }) => customer.account.email || '',
  getSurveyDetails: ({ customer }) => customer.surveyDetails || [],
  getEnrichRegistrationStatus: ({ customer }) => customer.enrichRegistration.status,
  isNewsletterOptIn: ({ customer }) => customer.account.newsletterOptIn || '',
  isCustomerLoaded: ({ customer }) => customer.isLoaded,
  isAccountLoaded: ({ customer }) => customer.account.id?.length > 0 || false,
  isMandatesLoaded: ({ customer }) => customer.mandates.length > 1,
  isCustomerCompany: ({ customer }) => customer.customer?.custom?.fields?.isCompany || false,
};
