import { QueryClient } from '@tanstack/react-query';
import Api from '../Api';
import { Preview } from '../../types/design';

interface GetPreviewsRequest {
  documentReferenceUrl: string;
  mcpSku: string;
  mcpAttributes: string;
  numberOfCanvasses: number;
  width: number;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
      placeholderData: (previousData: unknown) => previousData,
    },
  },
});

class ArtworkService extends Api {
  queryClient = queryClient;

  async getPreviews(params: GetPreviewsRequest): Promise<Preview[]> {
    return this.queryClient.fetchQuery<Preview[]>({
      queryKey: [
        'getPreviews',
        JSON.parse(params.mcpAttributes),
        params.mcpSku,
      ],
      queryFn: async () => {
        const { data } = await this.httpClient.get<Preview[]>(
          '/previews',
          {
            params: { ...params, documentReference: params.documentReferenceUrl },
          },
        );

        return data;
      },
    });
  }
}

export default ArtworkService;
