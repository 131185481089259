import { FormattedMessage } from 'react-intl';
import React from 'react';
import {
  Button, Heading, Link, LinkAsButton, useCopyToClipboard,
} from '@printdeal/ui-library';
import useSystemPages from '../../hooks/useSystemPages';
import { SentryHelper } from '../../helpers/SentryHelper';
import { getConfiguration } from '../../helpers/Configuration';

interface Props {
  /** If true, show a logging debug ID to the user */
  showDebugId: boolean;
  showRetryOption?: boolean;
}

const NextStepsForUserAfterError = ({
  showDebugId,
  showRetryOption = false,
}: Props) => {
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const configuration = getConfiguration();
  const [home] = useSystemPages('home');
  const homepagePath = home.path;

  const reload = () => window?.location?.reload();
  const debugId = SentryHelper.getDebugId();

  return (
    <div className="pd-text-sm">
      {showRetryOption && (
        <>
          <Heading as="span" variant="display" size="3" className="pd-mb-2">
            <FormattedMessage
              id="general.errorBoundary.v2.option.try_again.header"
              defaultMessage="Try again"
            />
          </Heading>
          <p>
            <FormattedMessage
              id="general.errorBoundary.v2.option.try_again.body"
              defaultMessage="
                  If the problem occurred for the first time, you could try again what you were doing on this page,
                  or return to the homepage:
                "
            />
          </p>
          <div className="pd-mt-3 pd-flex pd-flex-wrap pd-gap-3">
            <Button
              type="button"
              onClick={reload}
              variant="primary"
              iconBefore="refresh"
            >
              <FormattedMessage
                id="general.errorBoundary.v2.option.try_again.button_try_again_on_this_page"
                defaultMessage="Try again on this page"
              />
            </Button>
            <Button
              as="a"
              href={`${configuration.channel.siteUrl}${homepagePath}`}
              variant="secondary"
              iconBefore="home"
            >
              <FormattedMessage
                id="general.errorBoundary.v2.option.try_again.button_back_to_homepage"
                defaultMessage="Back to the homepage"
              />
            </Button>
          </div>
        </>
      )}
      <div className={showRetryOption ? 'pd-mt-6' : ''}>
        <Heading as="span" variant="display" size="3" className="pd-mb-2">
          <FormattedMessage
            id="general.errorBoundary.v2.option.contact_customer_care.header"
            defaultMessage="If the problem keeps occurring"
          />
        </Heading>
        <p className="pd-my-1">
          <FormattedMessage
            id="general.errorBoundary.v2.option.contact_customer_care.body"
            defaultMessage="Contact our customer care service, via phone or e-mail:"
          />
        </p>
        <div className="pd-mt-3 pd-flex pd-flex-col pd-gap-1">
          <Link
            as="a"
            href={`tel:${configuration.officeAddress.phoneNumber.replace(/[^0-9]/g, '')}`}
            variant="secondary"
            iconBefore="phone"
          >
            {configuration.officeAddress.phoneNumber}
          </Link>
          <Link
            as="a"
            href={`mailto:${configuration.mail.email}`}
            variant="secondary"
            iconBefore="envelope"
          >
            {configuration.mail.email}
          </Link>
        </div>
        { showDebugId && (
          <div className="pd-mt-6 pd-flex pd-flex-col pd-gap-2">
            <FormattedMessage
              id="general.errorBoundary.debug.contact_customer_care.body"
              defaultMessage="To serve you well, we ask you to pass this unique error code to the customer service:"
            />
            <LinkAsButton
              onClick={() => copyToClipboard(debugId)}
              variant="secondary"
              iconAfter={copiedText ? 'check' : 'copy'}
            >
              {debugId}
            </LinkAsButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default NextStepsForUserAfterError;
