import { createContext } from 'react';

export interface ISegmentContext {
  isSegmentAnalyticsReady: boolean;
  consentData?: {
    necessary: boolean;
    preferences: boolean;
    statistics: boolean;
    marketing: boolean;
  }
  isConsentReady: boolean
}

const defaultValue: ISegmentContext = {
  isSegmentAnalyticsReady: false,
  isConsentReady: false,
};

const SegmentContext = createContext(defaultValue);

export default SegmentContext;
