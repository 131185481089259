import { AxiosError, AxiosInstance } from 'axios';

class Api {
  httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public errorHandler(error: AxiosError) {
    if (error.response) {
      /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
      return Promise.reject(error.response.data);
    }
    if (error.request) {
      /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
      return Promise.reject(error.request);
    }
    // Something happened in setting up the request and triggered an Error
    return Promise.reject(error);
  }
}

export default Api;
