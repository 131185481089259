import { dequal } from 'dequal';
import { getVersions } from '../../src/helpers/version-check/get-versions';

interface LocationWithState extends Location {
  state: Record<string, any> | null
}

let reloadRequired = false;

const checkReloadRequired = async () => {
  try {
    // Load the latest version ID of the site
    const { current, latest } = await getVersions();
    reloadRequired = current !== latest;
  } catch (error) {
    // Non-critical feature, ignore on error
  }
};

export const onRouteUpdate = ({ location }: { location: LocationWithState }) => {
  // Check if a reload of the site is required
  if (reloadRequired) {
    // Check if there is a state that has other keys than "key" (which is automatically added by Gatsby)
    if (!location.state || dequal(Object.keys(location.state), ['key'])) {
      window.location.href = location.href;
    }
  }
};

export const onClientEntry = async () => {
  // Every 10 minutes, check if a new version is available
  setInterval(checkReloadRequired, 1000 * 60 * 10);
};
