/**
 * Get error message from a response
 *
 * @deprecated
 * Please consider just dumping raw and full error objects in your state, and then based on the typescript interface
 * of a response deal with the response structure in components that handle/render the error.
 * This function is getting more and more variations. It might be better to start looking into making http clients
 * return full error responses consistently, and then just typing them with typescript.
 * Instead of having a function which has to guess where the relevant into is.
 */
export const getHumanErrorFromResponse = (error: any): any => (
  error?.response?.data
  || error?.data
  || error?.message
  || `Undefined error: ${error}`
);
