import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { CartProvider } from '@printdeal/checkout-frontend';
import { SegmentationProvider } from '@printdeal/segmentation/react';
import { Root } from '@printdeal/ui-library';
import { actions as authActions } from '../store/slices/authSlice';
import store from '../store';
import SegmentScript from './segmentation/SegmentScript';
import SegmentProvider from './segmentation/SegmentProvider';
import AnalyticsTrackerProvider from './AnalyticsTracker/AnalyticsTrackerProvider';
import { getConfiguration } from '../helpers/Configuration';

import '../provide-configuration-values';
import '../provide-implementations';
import AuthCompatibilityComponent from './authentication/AuthCompatibilityComponent';

import queryClient from '../queryClient';

interface Props {
  element: React.ReactNode;
}

const RootElementWrapper = ({ element }: Props) => {
  // Immediately authenticate on first load
  store.dispatch(authActions.authenticate());

  return (
    <React.StrictMode>
      {/* icon-sprite.svg is located in ./static/generated/ */}
      <Root
        iconSpritePath="/generated/icons-sprite.svg"
        brandSpritePath="/generated/"
        brand={getConfiguration().channel.brand}
        brandSectionBgPrimaryPath="/generated/section-branded-asset-primary.svg"
        brandSectionBgWhitePath="/generated/section-branded-asset-white.svg"
      >
        <ReduxProvider store={store}>
          <QueryClientProvider client={queryClient}>
            <SegmentProvider>
              <SegmentationProvider>
                <AnalyticsTrackerProvider>
                  <CartProvider>
                    {element}
                  </CartProvider>
                </AnalyticsTrackerProvider>
                <SegmentScript />
              </SegmentationProvider>
            </SegmentProvider>
            <AuthCompatibilityComponent />
          </QueryClientProvider>
        </ReduxProvider>
      </Root>
    </React.StrictMode>
  );
};

export default RootElementWrapper;
