import { AxiosError } from 'axios';
import {
  Attachment,
  Complaint,
  ComplaintsResponse,
  CreateComplaintPayload,
  CreateComplaintResponse,
  GetComplaintsPayload,
} from '../../types/complaints';
import Api from '../Api';

class ComplaintsService extends Api {
  async getComplaints({ perPage, pageIndex }: GetComplaintsPayload): Promise<ComplaintsResponse> {
    const params = new URLSearchParams();

    if (perPage) {
      params.set('limit', String(perPage));
    }

    if (perPage && pageIndex) {
      if (pageIndex > 1) {
        const offset = (pageIndex - 1) * perPage;
        params.set('offset', String(offset));
      }
    }

    const { data } = await this.httpClient.get<ComplaintsResponse>('/complaints', { params })
      .catch(this.errorHandler);
    return data;
  }

  async getComplaint(id: string): Promise<Complaint> {
    const { data } = await this.httpClient.get<Complaint>(`/complaints/${id}`)
      .catch(this.errorHandler);
    return data;
  }

  async createComplaint(payload: CreateComplaintPayload): Promise<CreateComplaintResponse> {
    try {
      const { data } = await this.httpClient.post<CreateComplaintResponse>('/complaints', payload);
      return data;
    } catch (error) {
      // Throw the error back up. Won't be needed anymore when we migrate to react-hook-form & react-query.
      await this.errorHandler(error as AxiosError);
      throw error;
    }
  }

  async deleteAttachment(complaintId: string, attachmentId: string): Promise<'Attachment deleted'> {
    const { data } = await this.httpClient.delete<'Attachment deleted'>(`/complaints/${complaintId}/attachment/${attachmentId}`)
      .catch(this.errorHandler);
    return data;
  }

  async addAttachments(complaintId: string, attachments: Attachment[]): Promise<'Added attachment'> {
    const { data } = await this.httpClient.post<'Added attachment'>(`/complaints/${complaintId}/attachments`, attachments)
      .catch(this.errorHandler);
    return data;
  }
}

export default ComplaintsService;
