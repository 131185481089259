import { ErrorManagement } from '@printdeal/error-management/react';
import Api from '../Api';

class SurfaceDependencyService extends Api {
  getTechnicalDrawingPdfUrl = async (
    productId: string,
    selections: { [key: string]: string },
  ): Promise<string | undefined> => {
    try {
      const designTemplateResult = await this.httpClient.post<{ PdfUrl: string }>(
        '/v1/designTemplateGenerator',
        {
          productId,
          selections,
        },
      );

      const { data: { PdfUrl = '' } } = designTemplateResult;
      return PdfUrl;
    } catch (error) {
      ErrorManagement.exception({
        exception: error,
        extra: {
          productId,
          version: '1',
          selections: JSON.stringify(selections),
        },
        tags: {
          component: 'summary',
          fileLocation: 'SurfaceDependencyService.ts',
          featureFunction: 'getTechnicalDrawingPdfUrl',
        },
        fingerprint: ['printdeal-pdf-template-download'],
      });
      throw error;
    }
  };
}

export default SurfaceDependencyService;
