import axios from 'axios';

/** Get the latest and current version of the deploy */
export const getVersions = async () => {
  const { data } = await axios.get('/api/v1/build-version.json');
  return {
    current: process.env.GATSBY_DEPLOY_ID,
    latest: data.version,
  };
};
