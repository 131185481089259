import { useStaticQuery, graphql } from 'gatsby';
import type { Locale } from '../types/gatsby';

type Language = {
  locale: Locale,
  messages: {
    id: string,
    translation: string
  }[],
};

export type Messages = Record<string, string>;

const useTranslations = (locale: string) => {
  const { languages } = useStaticQuery<{ languages: { nodes: Language[] } }>(
    graphql`
      {
        languages: allTranslations {
          nodes {
            locale
            messages {
              id
              translation
            }
          }
        }
      }
    `,
  );

  const matchingLocale = languages.nodes.find((node) => node.locale === locale);
  const messages: Record<string, string> = Object.create(null);

  if (matchingLocale !== undefined) {
    for (let i = 0; i < matchingLocale.messages.length; i += 1) {
      const message = matchingLocale.messages[i];
      messages[message.id] = message.translation;
    }
  }

  return messages;
};

export default useTranslations;
