import { reducer as authReducer } from './slices/authSlice';
// @ts-expect-error
import { reducer as customerReducer } from './slices/customerSlice';

const rootReducer = () => ({
  auth: authReducer,
  customer: customerReducer,
});

export default rootReducer;
