import Api from '../Api';

interface IRequestObject {
  specification: string;
  orderId: string;
  lineItemId: string;
  customLineItemId: string;
  attachments?: any[];
}

interface IResponseObject {
  data?: {
    success: boolean;
  };
  reason?: string;
}

class Services extends Api {
  async createZendeskTicketDesignFile(reqObj: IRequestObject):Promise<IResponseObject | Error> {
    if (!reqObj) {
      return Promise.resolve({ reason: 'No request payload' });
    }

    try {
      return await this.httpClient.post('/artwork-services/create', reqObj);
    } catch (error: unknown) {
      if (error instanceof Error) {
        return Promise.resolve(error);
      }

      return Promise.resolve({ reason: 'API call failed' });
    }
  }

  async createZendeskTicketChangeFile(reqObj: IRequestObject):Promise<IResponseObject | Error> {
    if (!reqObj) {
      return Promise.resolve({ reason: 'No request payload' });
    }

    try {
      return await this.httpClient.post('/artwork-services/change', reqObj);
    } catch (error: unknown) {
      if (error instanceof Error) {
        return Promise.resolve(error);
      }

      return Promise.resolve({ reason: 'API call failed' });
    }
  }
}

export default Services;
