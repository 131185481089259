/* eslint-disable no-param-reassign */

/**
 * @param {string} string - String to capitalize
 *
 * @return {string} - Capitalized string
 */
export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Get an objects property value by path
 * @param {string} path - Path to search for, i.e. 'object.key.anotherKey'
 * @param {object} object - Object to search in
 *
 * @return {*} - The resolved value or undefined if path does not exist in object
 */
export const getValueByPath = (path, object = {}) => {
  const keys = path.split('.');
  let index = 0;
  let value = object;
  while (value && index < keys.length) {
    value = value[keys[index]];
    index += 1;
  }
  return index === keys.length ? value : undefined;
};

/**
 * @param {array} sortedIds - Array of sorted ids because return value should respect order
 * @param {object} byId - Object of entities keyed by id
 *
 * @return {object} - Object of stringified entity values keyed by id
 */
const createFilterableEntitiesById = ({
  sortedIds,
  byId,
}) => sortedIds.reduce((acc, id) => {
  const values = Object.values(byId[id]);
  const string = values.join(' ').toLowerCase();
  return {
    ...acc,
    [id]: string,
  };
}, {});

/**
 * @param {array} filterableById - Array of stringified entities
 * @param {string} filter - String to filter by
 *
 * @return {array} - Array of entity ids filtered by filter
 */
const getFilteredEntityIds = ({
  filterableById,
  filter,
}) => Object.keys(filterableById).filter(
  (id) => filterableById[id].includes(filter),
);

/**
 * @param {number} total - Total number of entities
 * @param {number} perPage - Number of entities per page
 *
 * @return {array} - Array of entity ids filtered by filter
 */
const getTotalPagesForEntity = ({
  total,
  perPage,
}) => Math.ceil(total / perPage) || 1;

/**
 * @param {array} ids - Array of entity ids
 * @param {number} perPage - Amount of entities per page
 * @param {number} page - Page number to get
 *
 * @return {array} - Array of entity ids for requested page
 */
const getPageOfEntitiesIds = ({
  ids,
  total = ids.length,
  perPage,
  page = 1,
}) => {
  const totalPages = getTotalPagesForEntity({
    total,
    perPage,
  });

  if (totalPages <= 1) {
    return ids;
  }

  const newPage = page > totalPages ? totalPages : page || 1;
  const prevPage = newPage > 1 ? newPage - 1 : 0;
  return ids.slice(
    prevPage * perPage,
    newPage * perPage,
  );
};

/**
 * Create initial entity state
 *
 * @param {object} state - Initial state
 *
 * @return {object} - Initial state
 */
export const createInitialEntityState = ({
  byId = {},
  ids = [],
  total = 0,
  filterableById = [],
  filteredIds = [],
  filter = '',
  visibleIds = [],
  currentPage = 1,
  totalPages = 1,
  perPage = Infinity,
} = {}) => ({
  byId,
  ids,
  total,
  filterableById,
  filteredIds,
  filter,
  visibleIds,
  currentPage,
  totalPages,
  perPage,
});

/**
 * Create reducers for entity
 * @param {string} type - Plural type name of entity, like 'addresses'
 *
 * @return {object} - Two common reducers for pagination and filtering entities
 */
export const createEntityReducers = ({ type }) => {
  const capitalizedType = capitalize(type);

  return {
    [`set${capitalizedType}Page`]: (state, { payload }) => {
      const { total } = state[type];
      const page = payload;
      const visibleIds = getPageOfEntitiesIds({
        ids: state[type].filteredIds,
        total,
        perPage: state[type].perPage,
        page,
      });

      state[type] = {
        ...state[type],
        visibleIds,
        currentPage: page,
      };
    },
    [`set${capitalizedType}Filter`]: (state, { payload }) => {
      const filter = payload;
      const filteredIds = getFilteredEntityIds({
        filterableById: state[type].filterableById,
        filter: filter.toLowerCase(),
      });
      const total = filteredIds.length;
      const totalPages = getTotalPagesForEntity({
        total,
        perPage: state[type].perPage,
      });
      const visibleIds = getPageOfEntitiesIds({
        ids: filteredIds,
        perPage: state[type].perPage,
      });

      state[type] = {
        ...state[type],
        filteredIds,
        filter,
        visibleIds,
        currentPage: 1,
        total,
        totalPages,
      };
    },
  };
};

/**
 * @param {mixed} state - Redux Toolkit Immer state
 * @param {array} entities - Array of raw entities to normalize
 * @param {string} idKey - Key to use as id
 * @param {function} normalizer - Function to normalize a single entity
 *
 * @return {object} - Normalized entities keyed by id
 */
const normalizeEntities = ({
  state,
  entities,
  idKey = 'id',
  normalizer,
}) => entities.reduce((acc, entity) => ({
  ...acc,
  [entity[idKey]]: normalizer({
    state,
    entity,
  }),
}), {});

/**
 * @param {string} type - Plural type name of entity, like 'addresses'
 * @param {string} idKey - Key to use as id
 * @param {bool} merge - Wether payload should be merged with or replace existing state
 * @param {function} normalizer - Function to normalize a single entity
 * @param {function} sorter - Sorting function to sort array of entities before normalization
 *
 * @return {function} - Function that maps array of entities payload to valid state
 */
export const createMapEntitiesPayloadToState = ({
  type,
  idKey = 'id',
  merge = true,
  /**
   * @param {object} entity - Entity
   *
   * @return {object} - Normalized entity
   */
  normalizer = ({ entity }) => entity,
  /**
   * @param {object} byId - Object of entities keyed by id
   *
   * @return {array} - Array of sorted ids
   */
  sorter = ({ byId }) => Object.keys(byId),
} = {}) => ({
  state,
  payload,
  page = 1,
}) => {
  const byId = {
    ...(merge && state[type].byId),
    ...normalizeEntities({
      state,
      entities: payload,
      idKey,
      normalizer,
    }),
  };
  const ids = sorter({
    state,
    byId,
  });
  const filterableById = createFilterableEntitiesById({
    sortedIds: ids,
    byId,
  });
  const filteredIds = ids;
  const total = filteredIds.length;
  const totalPages = getTotalPagesForEntity({
    total,
    perPage: state[type].perPage,
  });
  const visibleIds = getPageOfEntitiesIds({
    ids: filteredIds,
    perPage: state[type].perPage,
    page,
  });

  return {
    ...state[type],
    byId,
    ids,
    total,
    filterableById,
    filteredIds,
    filter: '',
    visibleIds,
    currentPage: page,
    totalPages,
  };
};

/**
 * Possible status for async actions
 */
const ASYNC_ACTION_STATUS = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  error: 'error',
};

/**
 * Create initial async action state
 *
 * @param {object} state - Initial state
 *
 * @return {object} - Initial state
 */
export const createInitialAsyncActionState = (state = {}) => ({
  status: ASYNC_ACTION_STATUS.idle,
  error: '',
  ...state,
});

/**
 * Create reducers for async actions
 * @param {string} type - Async action type, i.e. 'getUser'
 * @param {function} onRequest - Callback function for request type action
 * @param {function} onSuccess - Callback function for success type action
 * @param {function} onFailure - Callback function for error type action
 *
 * @return {object} - Reducers for async action with request, success and error types
 */
export const createAsyncActionReducers = ({
  type,
  onRequest = () => {},
  onSuccess = () => {},
  onFailure = () => {},
}) => ({
  [type]: () => {},
  [`${type}Request`]: (state, action) => {
    state[type] = {
      ...state[type],
      status: ASYNC_ACTION_STATUS.loading,
      error: '',
    };
    onRequest(state, action);
  },
  [`${type}Success`]: (state, action) => {
    state[type] = {
      ...state[type],
      status: ASYNC_ACTION_STATUS.success,
    };
    onSuccess(state, action, type);
  },
  [`${type}Failure`]: (state, action) => {
    state[type] = {
      ...state[type],
      status: ASYNC_ACTION_STATUS.error,
      error: action?.payload?.error || '',
    };
    onFailure(state, action, type);
  },
});
