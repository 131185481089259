import { addDataLayerEntry, Events } from '@printdeal/data-layer-manager';
import { windowPresent, LS_KEYS, getFromLocalStorage } from '../window';

const AuthEvents = {
  UserDataAdded: 'userDataAdded',
  UserDataRemoved: 'userDataRemoved',
};

class AuthDataLayerHelper {
  /**
   * @param location {string} For example: 'Checkout'
   */
  static dispatchLoginViewDataLayerEvent(location) {
    // GA4
    addDataLayerEntry('view_login', {
      location,
    });

    // GA3
    addDataLayerEntry('gaEvent', {
      gaEvent: {
        eventCategory: location,
        eventAction: 'loginView',
        eventNonInteraction: true,
      },
    });
  }

  /**
   * GA4 version of `dispatchDataLayerEventOnLogin`, which has different parameters and needs to be called
   * from a different place in the code to be able to access this data.
   * @param location {string} For example: 'Checkout'
   * @param userId {string}
   * @param eventLabel {string}
   */
  static dispatchLoginSuccessDataLayerEvent(location, userId, eventLabel) {
    // GA4
    addDataLayerEntry('login', {
      location,
      user_id: userId,
      eventLabel,
    });

    // GA3
    addDataLayerEntry('gaEvent', {
      gaEvent: {
        eventCategory: location,
        eventAction: 'loginView',
        eventNonInteraction: true,
        eventLabel,
      },
    });
  }

  /**
   * GA3 version of `dispatchLoginSuccessDataLayerEvent`.
   * @param {object} decodedAuthToken
   * @param {object} customerData
   * @param {boolean} isOnChangeRoute
   */
  // eslint-disable-next-line no-unused-vars
  static dispatchDataLayerEventOnLogin(decodedAuthToken, customerData, isOnChangeRoute = false) {
    if (!isOnChangeRoute && getFromLocalStorage(LS_KEYS.CUSTOMER_NUMBER)) {
      // Prevent double userDataAdded entry
      return;
    }

    addDataLayerEntry(AuthEvents.UserDataAdded, {
      userId: decodedAuthToken.account.id,
      userEmail: decodedAuthToken.account.email,
    });
  }

  /**
   * User has logged out event
   */
  static dispatchDataLayerEventOnLogout() {
    addDataLayerEntry(AuthEvents.UserDataRemoved, {
      userId: undefined,
      userEmail: undefined,
    });
  }

  static enableLoginEventOnRouteUpdate() {
    return windowPresent
      && (!window.dataLayer
        || window.dataLayer.length === 0
        || (window.dataLayer.length < 20 // 20 events without a single pageView? No way!
          && !window.dataLayer.find(
            (dataLayerEvent) => dataLayerEvent.event && dataLayerEvent.event === Events.PageView,
          )));
  }

  /**
   * User authenticated on initial load
   * @param {object} decodedAuthToken
   */
  static dispatchDataLayerEventOnAuthenticate(decodedAuthToken) {
    addDataLayerEntry(AuthEvents.UserDataAdded, {
      userId: decodedAuthToken.account.id,
      userEmail: decodedAuthToken.account.email,
    });
  }
}

export {
  AuthDataLayerHelper,
};
