import React, { useReducer, useCallback, useMemo } from 'react';
import AnalyticsTrackerContext from './AnalyticsTrackerContext';
import type {
  Dispatcher,
  TrackingIds,
  TrackingData,
  AnalyticsTracker,
  AnalyticsTrackerContextInterface,
} from './AnalyticsTrackerContext';
import { trackingDataExistsForId, findIndexForPlacementId, getUpdatedStateForEventsTrail } from './AnalyticsTrackerHelper';

interface ChildrenProps {
  children?: React.ReactNode;
}

interface PayloadTrackingIds {
  trackingIds: TrackingIds
}

interface PayloadProductIdTrail {
  productId: string;
  placementId?: string;
  trailName: 'productsClicked' | 'productsAddedToCart' | 'orderCreatedForProducts'
}

export type Actions = {
  [Key in keyof ActionsMap]: {
    type: Key;
    payload: ActionsMap[Key];
  }
}[keyof ActionsMap];

export type ActionsMap = {
  /**
   * populate a new object (with trackingIds) for CT Recommendations
   * in the AnalyticsTracker Context for a placement viewed
   */
  'CT_ADD_NEW_TRACKING_DATA': PayloadTrackingIds;
  /**
   * populate poductids in the AnalyticsTracker Context for Recommendation Clicked/AddedToCart/Order Created trails
   */
  'CT_ADD_NEW_TRACKING_DATA_FOR_EVENTS_TRAIL': PayloadProductIdTrail;
  /**
   * Clears CT recommendations in the AnalyticsTracker Context
   */
  'CT_CLEAR_TRACKING_DATA': undefined;
  'ALGFOLIA_ADD_NEW_TRACKING_DATA': TrackingData;
};

const trackingDataReducer = (state: AnalyticsTracker, action: Actions) => {
  switch (action.type) {
    case 'CT_ADD_NEW_TRACKING_DATA': {
      const { trackingIds: { placementId } } = action.payload;
      if (!trackingDataExistsForId(state.ctRecommendations, placementId)) {
        return {
          ...state,
          ctRecommendations: [...state.ctRecommendations, action.payload],
        };
      }
      return state;
    }
    case 'CT_ADD_NEW_TRACKING_DATA_FOR_EVENTS_TRAIL': {
      const { productId, placementId, trailName } = action.payload;
      if (trackingDataExistsForId(state.ctRecommendations, placementId)) {
        const indexOfDataToBeUpdated = findIndexForPlacementId(state.ctRecommendations, placementId);
        const eventLogsTrail = state.ctRecommendations[indexOfDataToBeUpdated]?.productIdsForEventsLogged;
        const eventTrailExists = eventLogsTrail?.[trailName];
        const dataToBeUpdated = !eventTrailExists ? {
          ...eventLogsTrail,
          [trailName]: [],
        } : eventLogsTrail;

        return {
          ...state,
          ctRecommendations: getUpdatedStateForEventsTrail(
            state,
            indexOfDataToBeUpdated,
            dataToBeUpdated,
            productId,
            trailName,
          ),
        };
      }
      return state;
    }
    case 'CT_CLEAR_TRACKING_DATA': {
      return {
        ...state,
        ctRecommendations: [],
      };
    }
    default: {
      return state;
    }
  }
};

const AnalyticsTrackerProvider = ({ children }: ChildrenProps) => {
  const [state, dispatch] = useReducer<typeof trackingDataReducer>(
    trackingDataReducer,
    { algoliaRecommendations: [], ctRecommendations: [] },
  );

  const dispatchToAnalyticsTracker: Dispatcher = useCallback((type, ...payload) => {
    dispatch({ type, payload: payload[0] } as Actions);
  }, []);

  /**
   * Reducer yields out a new state, if any action dispatched and changed
   * if the state is not changing then we dont need to change the provider value (No un-necessary re-render)
   * If we pass [state, dispatchToAnalyticsTracker] as is in the provider value...
   * it will be detected as a new array every time (creating perfromance issue), hence memoizing it
   */
  const context: AnalyticsTrackerContextInterface = useMemo(
    () => [state, dispatchToAnalyticsTracker],
    [state, dispatchToAnalyticsTracker],
  );

  return (
    <AnalyticsTrackerContext.Provider value={context}>
      {children}
    </AnalyticsTrackerContext.Provider>
  );
};

export default AnalyticsTrackerProvider;
