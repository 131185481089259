import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import type { Locale } from '../types/gatsby';
import type { Messages } from '../hooks/useTranslations';

type IntlProviderProps = {
  children: React.ReactNode;
  localeFromPage?: Locale;
  messages: Messages;
};

const customFormats = {
  number: {
    noGroupingFormat: {
      useGrouping: false,
    },
  },
};

const IntlProvider = ({
  children,
  localeFromPage,
  messages,
}: IntlProviderProps) => {
  const { portalConfiguration } = useStaticQuery<{ portalConfiguration: { defaultLocale: Locale } }>(graphql`
    {
      portalConfiguration {
        defaultLocale
      }
    }
  `);
  const { defaultLocale } = portalConfiguration;
  // Get locale from node, fallback to locale from state
  const locale = localeFromPage || defaultLocale;

  return (
    <ReactIntlProvider
      key={locale} // See https://github.com/formatjs/react-intl/blob/master/docs/Components.md#dynamic-language-selection
      locale={locale}
      defaultLocale={defaultLocale}
      messages={messages}
      formats={customFormats}
    >
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
