import { all } from 'redux-saga/effects';
import authSaga from './sagas/authSaga';
// @ts-expect-error
import customerSaga from './sagas/customerSaga';

export default function* rootSaga() {
  yield all([
    ...authSaga,
    ...customerSaga,
  ]);
}
