import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import type { AnalyticsSnippet } from '@segment/analytics-next';
import { useSelector } from 'react-redux';
import SegmentContext from './SegmentContext';
import { ConsentData, getConsentData } from '../../helpers/CookiebotHelper';
import { selectors as authSelectors } from '../../store/slices/authSlice';
import { SentryHelper } from '../../helpers/SentryHelper';
// @ts-expect-error
import { selectors as customerSelectors } from '../../store/slices/customerSlice';

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

interface Props {
  children?: React.ReactNode;
}

const SegmentProvider = ({ children }: Props) => {
  const [consentData, setConsentData] = useState<ConsentData>();
  const [isSegmentAnalyticsReady, setIsSegmentAnalyticsReady] = useState<boolean>(false);
  const [isConsentReady, setIsConsentReady] = useState<boolean>(false);
  const isAuthLoading = useSelector(authSelectors.isAuthLoading);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const accountId: string = useSelector(customerSelectors.getAccountId);

  useEffect(() => {
    function onConsentReady() {
      setConsentData(getConsentData());
      setIsConsentReady(true);
    }

    function onSegmentAnalyticsReady() {
      setIsSegmentAnalyticsReady(true);
    }

    window.addEventListener('CookiebotOnConsentReady', onConsentReady);
    window.addEventListener('SegmentAnalyticsReady', onSegmentAnalyticsReady);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', onConsentReady);
      window.removeEventListener('SegmentAnalyticsReady', onSegmentAnalyticsReady);
    };
  }, []);

  const identifyLoggedInUser = useCallback((userId: string) => {
    window.analytics.identify(userId, {}, { context: { consent: consentData } });
  }, [consentData]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    try {
      if (!isAuthLoading && consentData?.statistics && consentData?.marketing && isSegmentAnalyticsReady && accountId) {
        identifyLoggedInUser(accountId);
      }
    } catch (error) {
      SentryHelper.exception({
        e: error,
        tags: {
          fileLocation: 'SegmentProvider',
        },
        fingerPrint: ['segment-provider'],
      });
    }
  }, [isAuthLoading, isLoggedIn, consentData, isSegmentAnalyticsReady, accountId, identifyLoggedInUser]);

  const traitsContext = useMemo(() => ({
    isSegmentAnalyticsReady,
    consentData,
    isConsentReady,
  }), [isSegmentAnalyticsReady, consentData, isConsentReady]);

  return (
    <SegmentContext.Provider value={traitsContext}>
      {children}
    </SegmentContext.Provider>
  );
};

export default SegmentProvider;
