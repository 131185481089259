import { configureStore } from '@reduxjs/toolkit';
import sagaMiddlewareFactory from 'redux-saga';
import { LS_KEYS } from '../helpers/window';
// @ts-expect-error
import createLocalStorageMiddleware from './middleware/localStorageMiddleware';
// @ts-expect-error
import authCompatibilityMiddleware from './middleware/authCompatibilityMiddleware';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const isDev = process.env.GATSBY_ACTIVE_ENV !== 'production';
const isLogger = isDev && process.env.REDUX_LOGGER === 'true';

const sagaMiddleware = sagaMiddlewareFactory();
const payloadToLocalStorageKeyMap = [
  ['token', LS_KEYS.AUTH_TOKEN],
  ['auth.anonymousCOAM.identity', LS_KEYS.ANON_COAM_IDENTITY],
  ['customer.customerNumber', LS_KEYS.CUSTOMER_NUMBER],
  ['customer.customerType', LS_KEYS.CUSTOMER_TYPE],
];
const localStorageSyncer = createLocalStorageMiddleware({
  payloadToLocalStorageKeyMap,
  isLogger,
});

const middleware = [
  sagaMiddleware,
  localStorageSyncer,
  authCompatibilityMiddleware,
];

const store = configureStore({
  reducer: rootReducer(),
  middleware,
  devTools: isDev,
});

export default store;

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
