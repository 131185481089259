import Api from '../Api';
import { AccountSurveyData } from '../../components/my-account/components/widgets/surveys/types/survey-types';

interface IEnrichUser {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: string;
  isCompany: string;
  company: string;
  kvkNumber: string;
  vatId: string;
  streetName: string;
  streetNumber: string;
  additionalStreetInfo: string;
  postalCode: string;
  city: string;
  country: string;
  isSubscribed: boolean;
}

export interface IAccount {
  cocNumber: string;
  company: string;
  companyName: string;
  dateOfBirth?: string;
  email: string;
  firstName: string;
  isCompany: boolean;
  lastName: string;
  locale: string;
  newsletterOptIn: boolean;
  vatId: string;
}

interface IUpdatableAccount extends Partial<IAccount> {
  newPassword?: string;
  currentPassword?: string;
}

class CustomerService extends Api {
  /**
   * Performs call to obtain customer data.
   *
   * @returns {Promise<*>}
   */
  async getCustomer() {
    const { data } = await this.httpClient.get('/customer/getCustomer');
    return Promise.resolve(data);
  }

  /**
   * Performs call to obtain account data.
   *
   * @returns {Promise<*>}
   */
  async getAccount() {
    const { data } = await this.httpClient.get('/customer/getAccount');
    return Promise.resolve(data);
  }

  async updateAccount(account: IUpdatableAccount) {
    const { data } = await this.httpClient
      .post('/customer/updateAccountDetails', account)
      .catch(this.errorHandler);
    return Promise.resolve(data);
  }

  async addCustomerToResellerGroup() {
    const { data } = await this.httpClient
      .post('/customer/addCustomerToResellerGroup')
      .catch(this.errorHandler);
    return Promise.resolve(data);
  }

  /**
   * Authorize a customer to the Printdeal API
   *
   * @param {string} redirectUri
   * @param {string} authenticationId
   *
   * @returns {Promise<*>}
   */
  async authorizePrintdealAPI(redirectUri: string, authenticationId: string) {
    try {
      const data = await this.httpClient.post(
        '/customer/authorizeCustomer',
        {
          redirectUri,
          authenticationId,
        },
      );
      return data;
    } catch (error) {
      // @ts-expect-error
      const { response } = error;
      return response;
    }
  }

  /**
   * Enrich the customer data through registrationEnrichment.
   * @param {object} payload
   */
  async enrichRegistration(payload: IEnrichUser) {
    const { kvkNumber, ...rest } = payload;
    const data = await this.httpClient.post(
      '/customer/register',
      {
        ...rest,
        cocNumber: kvkNumber,
      },
    );
    return Promise.resolve(data);
  }

  async addSurveyAnswersToCustomer(accountSurveyData: AccountSurveyData) {
    const { data } = await this.httpClient.post(
      '/customer/updateSurveyData',
      {
        ...accountSurveyData,
      },
    );
    return data.account;
  }
}

export default CustomerService;
